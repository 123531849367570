@import "/styles/variables.scss";
.Home {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
  grid-column: 1;
  grid-row: 2;

  a {
    text-decoration: none;
  }

  .cta {
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    text-decoration: none;
  }
  
  .primaryCta {
    color: white;
    background-color: $primary-blue;
    border-color: $primary-blue;
  }
  
  .secondaryCta {
    color: $primary-blue;
    background-color: #F2F2F2;
    border-color: #F2F2F2;
  }

  h2 {
    color: $primary-blue;
    z-index: 10;
    margin: 0px;
    position: relative;
  }

  h3 {
    font-weight: normal;
  }

  .header {
    position: relative;
    color: #FFFFFF;
    overflow: hidden;

    .overlay {
      z-index: 3;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      opacity: .4;
      background: -moz-linear-gradient(90deg, rgb(0,0,0) 0%, rgb(0,0,0) 100%);
      background: -webkit-linear-gradient(90deg, rgb(0,0,0) 0%, rgb(0,0,0) 100%);
      background: linear-gradient(90deg, rgb(0,0,0) 0%, rgb(0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }

    .background {
      z-index: 0;
    }

    h3,
    .primaryCta,
    .secondaryCta,
    .logoContainer {
      z-index: 5;
      position: inherit;
    }

    .logoContainer img {
      width: auto !important;
      max-width: auto !important;
      min-width: auto !important;
      margin: 0px !important;
    }
  }

  

  .featuredServices {
    background-color: #FFFFFF;
    position: relative;

    .styleBar {
      position: absolute;
      height: 100%;
      background-color: #DEDEDE;
      z-index: 5;
    }

    .tileContainer {
      display: -ms-grid;
      display: grid;
      margin: auto;
    }

    .tile,
    .tile * {
      z-index: 10;
      position: relative;
      text-decoration: none;
    }

    .tile {
      display: inline-block;
      background-color: #FFFFFF;
    }

    .tileImage {
      width: 100%;
      position: relative;

      .overlay {
        z-index: 11;
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgb(56,58,57);
        background: -moz-linear-gradient(0deg, rgba(56,58,57,0.7) 0%, rgba(56,58,57,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(56,58,57,0.7) 0%, rgba(56,58,57,0) 100%);
        background: linear-gradient(0deg, rgba(56,58,57,0.7) 0%, rgba(56,58,57,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#383a39",endColorstr="#383a39",GradientType=1);
      }
  
    }

    .descriptionStyleBar {
      display: block;
      border-top: 2px solid $secondary-blue;
    }

    h6 {
      color: $secondary-blue;
    }
  }

  .whyWeExcel {

    .infoContainer {
      display: -ms-grid;
      display: grid;
    }

    .leftCell {

      p {
        margin: auto;
      }

    }

    .rightCell {
      text-align: center;
      align-self: center;
      -ms-grid-column-align: center;
      position: relative;
    }

    .ctaContainer {
      position: absolute;
      background-color: #F2F2F2;
    }

    .primaryCta {
      display: inline-block;
      background-color: $primary-yellow;
      color: #FFFFFF;
      border-color: $primary-yellow;
    }
  }

  .marketsWeServe {
    color: #FFFFFF;

    h2 {
      color: #FFFFFF;
    }

    .primaryCta {
      color: $primary-blue;
      border-color: #FFFFFF;
      background-color: #FFFFFF;
    }

    h5 {
      margin: 0px auto;
    }

    ul {
      margin: 0px auto;
    }
  }

  @keyframes slide-animation {
    0% {opacity: 0;}
    30% {opacity: 1;}
    70% {opacity: 1;}
    100% {opacity: 0;}
  }

  @-webkit-keyframes slide-animation {
    0% {opacity: 0;}
    30% {opacity: 1;}
    70% {opacity: 1;}
    100% {opacity: 0;}
  }
}

@media screen and (min-width: 0px){

  .Home {
    font-size: $mobile-text;

    .cta {
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      padding: 8px 12px;
    }
  
    .cta svg {
      font-size: 18px;
      margin-bottom: -3px;
      margin-left: 5px;
    }

    h2 {
      padding: 25px 10px 10px;
    }

    .header {
      height: 235px;

      .logoContainer {
        width: auto;
        height: 50px;
        margin: 25px auto 0px 10px;
      }

      h3 {
        margin: 0px 0px 30px 10px;
      }

      .primaryCta {
        margin: 10px 0px 0px 8px;
      }

      .secondaryCta {
        margin: 10px 0px 0px 10px;
      }
      
    }

    .featuredServices {
      
      .styleBar {
        width: 45px;
      }
      
      .tileContainer {
        -ms-grid-columns: auto auto auto auto auto auto auto auto;
        grid-template-columns: auto auto auto auto auto auto auto auto;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 30px;
      }

      .tile {
        -ms-grid-column-align: center;
        justify-self: center;
        
        width: 150px;
        margin: 5px 10px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, .16);
      }

      .tile:last-of-type::after {
        position: absolute;
        content: '';
        right: -50px;
        width: 50px;
        height: 100%;
      }

      .tile:active,
      .tile:focus {
        width: 160px;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, .16);
        margin: 0px 5px;
      }

      .tile:active .tileImage,
      .tile:focus .tileImage {
        height: 125px;
      }

      .tileImage {
        height: 115px;
      }

      .tile:active .descriptionStyleBar,
      .tile:focus .descriptionStyleBar {
        margin: 8px 10px 0px;
      }

      .descriptionStyleBar {
        width: 20%;
        border-top: 1px solid $secondary-blue;
        margin: 8px 5px 0px;
      }

      .tile:active h6,
      .tile:focus h6 {
        margin: 3px 10px 10px;
      }

      h6 {
        margin: 3px 5px 10px;
      }

      .styleGridOne,
      .styleGridTwo,
      .styleGridThree {
        display: none;
      }
    }

    .whyWeExcel {
      padding-bottom: 35px;

      .infoContainer {
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        margin: auto 10px;
      }

      .logoContainer img {
        height: 65px;
        margin: 15px 20px; 
      }

      .leftCell {
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: 2;
        -ms-grid-row: 2;
        margin: 10px 0px;
        text-align: center;

        p {
          text-align: left;
        }

      }

      .rightCell {
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: 1;
        -ms-grid-row: 1;
        width: 100%;
        height: 150px;
        margin: 0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .primaryCta {
        float: left;
        margin: 15px auto 0px -2px;
      }
    }

    .marketsWeServe {
      background-color: $primary-blue;
      position: relative;

      .leftCell {
        margin: 0px 10px;
        z-index: 10;
        width: auto;

        h2 {
          padding: 25px 0px 10px;
        }

        div {
          margin: 0px auto 10px auto;
        }
    
        ul {   
          padding-left: 20px;
        }

        .mobileColumn {
          display: inline-block;
          margin-right: 20px;
        }

        .ctaContainer {
          margin-bottom: 0px;
        }
    
        .primaryCta {
          display: inline-block;
          margin: 5px auto 35px -3px;
        }
      }

      .trapezoid {
        display: none;
      }

      .mobileImageContainer {
        position: relative;
        height: 150px;
        width: 100%;
      }

      .imageContainer {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 150px;
        width: 100%;
        opacity: 0;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center right;
      }

      .imageContainer.active {
        -webkit-animation: slide-animation 5s ease;
        -moz-animation: slide-animation 5s ease;
        -ms-animation: slide-animation 5s ease;
        -o-animation: slide-animation 5s ease;
        animation: slide-animation 5s ease;
      }
      
    }
  }

}

@media screen and (min-width: 500px){

  .Home {
    font-size: $tablet-text;

    .cta {
      border-width: 2px;
      border-style: solid;
      padding: 5px 14px;
    }
  
    .cta svg {
      font-size: 20px;
      margin-bottom: -3px;
      margin-left: 6px;
    }

    h2 {
      padding: 40px 30px 15px;
    }

    .header {
      height: 400px;

      .logoContainer {
        height: 75px;
        width: auto;
        margin: 70px 0px 0px 30px;
      }

      h3 {
        margin: 0px 0px 20px 30px;
      }

      .primaryCta {
        margin: 0px 0px 0px 28px;
      }

      .secondaryCta {
        margin: 0px 0px 0px 15px;
      }
    }

    .featuredServices {
      
      .styleBar {
        width: 85px;
      }

      .tileContainer {
        -ms-grid-columns: 33.3% 33.3% 33.3%;
        grid-template-columns: 33.3% 33.3% 33.3%;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        padding-bottom: 60px;
        overflow: hidden;
      }

      @media (max-width: 600px) {
        .tileContainer {
          -ms-grid-columns: 50% 50%;
          grid-template-columns: 50% 50%;
        }
      }

      .tile {
        -ms-grid-column-span: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column-align: center;
        justify-self: center;
        
        width: 85%;
        margin: 15px 0px;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, .16);
      }

      .tile:active,
      .tile:focus {
        width: 90%;
        box-shadow: 8px 8px 12px rgba(0, 0, 0, .16);
        margin: 5px -2.5%;
      }

      .tile:active .tileImage,
      .tile:focus .tileImage {
        height: 145px;
      }

      .tileImage {
        height: 135px;
      }

      .tile:active .descriptionStyleBar,
      .tile:focus .descriptionStyleBar {
        margin: 15px 15px 0px;
      }

      .descriptionStyleBar {
        width: 20%;
        margin: 10px 10px 0px;
      }

      .tile:active h6,
      .tile:focus h6 {
        margin: 3px 15px 15px;
      }

      h6 {
        margin: 3px 10px 15px;
      }   

      .styleGridOne,
      .styleGridTwo,
      .styleGridThree {
        display: none;
      }
    }

    .whyWeExcel {
      padding-bottom: 35px;

      .infoContainer {
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        margin: 0px 30px;
      }

      .logoContainer img {
        height: 125px;
        margin: 15px 50px;      
      }

      .leftCell {
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: 2;
        -ms-grid-row: 2;
        margin: 15px 0px;
        text-align: center;
        width: auto;

        p {
          text-align: left;
        }

      }

      .rightCell {
        display: block;
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: 1;
        -ms-grid-row: 1;
        width: 100%;
        margin: 0px;
        height: 330px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      @media (max-width: 725px) {

        .logoContainer img {
          height: 100px;
          margin: 15px 20px;      
        }

        .rightCell {
          height: 280px;
        }

      }

      .primaryCta {
        float: left;
        margin: 10px auto 0px -2px;
      }
    }

    .marketsWeServe {
      background-color: $primary-blue;
      position: relative;

      .leftCell {
        margin: 0px 30px;
        z-index: 10;
        width: auto;

        h2 {
          padding: 40px 0px 15px;
        }

        div {
          margin: 0px auto 15px 0px;
        }

        div:last-of-type {
          margin: 0px;
          padding: 5px 0px 60px;
        }

      }

      .mobileColumn {
        display: block;
      }

      .primaryCta {
        display: inline-block;
        margin: 0px auto 0px -3px;
      }

      ul {   
        padding-left: 25px;
      }

      .trapezoid {
        display: none;
      }

      .imageContainer {
        position: absolute;
        top: auto;
        left: auto;
        right: 15%;
        bottom: 173px;
        height: 300px;
        width: 50%;
        opacity: 0;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center right;
      }

      @media (max-width: 800px) {

        .imageContainer {
          right: 30px;
        }
        
      }

      .imageContainer.active {
        -webkit-animation: slide-animation 5s ease;
        -moz-animation: slide-animation 5s ease;
        -ms-animation: slide-animation 5s ease;
        -o-animation: slide-animation 5s ease;
        animation: slide-animation 5s ease;
      }

    }
  }

}

@media screen and (min-width: 950px){

  .Home {
    font-size: $web-text;

    .cta {
      border-width: 2px;
      border-style: solid;
      border-radius: 6px;
      padding: 6px 15px;
    }
  
    .cta svg {
      font-size: 25px;
      margin-bottom: -5px;
      margin-left: 6px;
    }

    h2 {
      padding: 70px 70px 35px;
    }

    .header {
      height: 700px;

      .logoContainer {
        height: 140px;
        width: auto;
        margin: 90px 0px 0px 70px;
      }

      h3 {
        font-size: 45px;
        margin: 0px 0px 25px 70px;
      }

      .primaryCta {
        margin: 0px 0px 0px 65px;
      }

      .secondaryCta {
        margin: 0px 0px 0px 20px;
      }
    }

    .featuredServices {

      .styleBar {
        width: 180px;
      }

      .tileContainer {
        -ms-grid-columns: 25% 25% 25% 25%;
        grid-template-columns: 25% 25% 25% 25%;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        padding-bottom: 40px;
        margin: auto 20px;
      }

      @media (max-width: 1300px) {
        .tileContainer {
          -ms-grid-columns: 33.3% 33.3% 33.3%;
          grid-template-columns: 33.3% 33.3% 33.3%;
        }
      }

      .tile {
        -ms-grid-column-span: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column-align: center;
        justify-self: center;

        width: 85%;
        margin: 15px 0px 60px;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, .36);
      }

      .tile:hover {
        width: 90%;
        box-shadow: 8px 8px 12px rgba(0, 0, 0, .16);
        margin: 10px -2.5% 55px;
      }

      .tile:hover .tileImage {
        height: 210px;
      }

      .tileImage {
        height: 200px;
      }

      @media (min-width: 1100px) {
        .tile:hover .tileImage {
          height: 260px;
        }
    
        .tileImage {
          height: 250px;
        }
      }

      @media (min-width: 1500px) {
        .tile:hover .tileImage {
          height: 310px;
        }
    
        .tileImage {
          height: 300px;
        }
      }

      .tile:hover .descriptionStyleBar {
        margin: 15px 20px 0px 30px;
      }

      .descriptionStyleBar {
        width: 16%;
        margin: 15px 20px 0px;
      }

      .tile:hover h6 {
        margin: 3px 20px 20px 30px;
      }

      h6 {
        margin: 3px 20px 20px;
        min-height: 50px;
      }

      .styleGridOne {
        grid-column: 3;
        grid-row: 2;
        -ms-grid-column: 3;
        -ms-grid-row: 2;
        width: 246px;
        height: 65px;
        margin: auto auto 25px auto;
        z-index: 5;
      }

      .styleGridTwo {
        grid-column: 4;
        grid-row: 1;
        -ms-grid-column: 4;
        -ms-grid-row: 1;
        width: 138px;
        height: 285px;
        margin: -15px -10px auto auto;
        z-index: 8;
      }

      .styleGridThree {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        width: 109px;
        height: 226px;
        margin: 60px -35px auto auto;
        z-index: 8;
      }

      @media (max-width: 1300px) {
        .styleGridOne {
          grid-column: 2;
          grid-row: 1;
          -ms-grid-column: 2;
          -ms-grid-row: 1;
          margin: auto auto 22px auto;
        }
    
        .styleGridTwo {
          grid-column: 3;
          grid-row: 2;
          -ms-grid-column: 3;
          -ms-grid-row: 2;
          margin: auto -10px 20px auto;
        }
    
        .styleGridThree {
          grid-column: 1;
          grid-row: 3;
          -ms-grid-column: 1;
          -ms-grid-row: 3;
          margin: auto auto 25px -6px;
        }
      }
    }

    .whyWeExcel {
      padding-bottom: 150px;

      .infoContainer {
        -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
        margin: auto;
      }

      .logoContainer img {
        height: 115px;
        margin: 15px 30px;      
      }

      @media (max-width: 1200px) {

        .logoContainer img {
          height: 75px;
          margin: 15px 30px;      
        }

      }

      .leftCell {
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: 1;
        -ms-grid-row: 1;
        margin-left: 70px;
      }

      .rightCell {
        grid-column: 2;
        grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        width: 80%;
        height: 80%;
        z-index: 10;
        margin: 10px auto auto auto;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .ctaContainer {
        left: -40px;
        bottom: -35px;
        border: 15px solid #F2F2F2;
        height: auto;

        .cta {
          margin-top: 0px;
        }
      }
    }

    .marketsWeServe {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
      -ms-grid-rows: 33.3% 33.3% 33.3%;
      grid-template-rows: 33.3% 33.3% 33.3%;

      .leftCell {
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: 1 / 4;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        margin: 0px 0px 0px 70px;
        position: relative;
        z-index: 10;
        width: 95%;

        h2 {
          padding: 70px 0px 35px;
        }

        div {
          margin: 0px auto 35px 0px;
          max-width: 700px;
        }

        div.listContainer {
          display: inline-block;
          margin: 0px 30px 30px auto;
        }

        div:last-of-type {
          margin-bottom: 150px;
        }
      }

      .primaryCta {
        margin: 15px auto 15px -3px;
      }

      h5 {
        margin: 0px auto;
      }

      ul {
        padding-left: 35px;
      }

      .trapezoid {
        display: block;
        position: relative;
        grid-column: 1 / 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        grid-row: 1 / 4;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        height: 100%;
        width: 100%;
        z-index: 5;

        background: rgb(0,76,151);
        background: -moz-linear-gradient(105deg, rgba(0,76,151,1) 55%, rgba(0,76,151,0) 55%);
        background: -webkit-linear-gradient(105deg, rgba(0,76,151,1) 55%, rgba(0,76,151,0) 55%);
        background: linear-gradient(105deg, rgba(0,76,151,1) 55%, rgba(0,76,151,0) 55%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#004c97",endColorstr="#004c97",GradientType=1);
      }

      .standbyGenerators,
      .waterPlant,
      .powerPlant {
        position: relative;
        opacity: 1;
        height: auto;
        top: 0px;
        right: 0px;
      }

      .standbyGenerators {
        grid-column: 1 / 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        grid-row: 1;
        -ms-grid-row: 1;
        width: 65%;
        -ms-grid-column-align: end;
        justify-self: end;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center right;
      }

      .waterPlant {
        grid-column: 1 / 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        grid-row: 2;
        -ms-grid-row: 2;
        width: 65%;
        -ms-grid-column-align: end;
        justify-self: end;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center right;
      }

      .powerPlant {
        grid-column: 1 / 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        grid-row: 3;
        -ms-grid-row: 3;
        width: 65%;
        -ms-grid-column-align: end;
        justify-self: end;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom right;
      }
    }
  }

}